import React from 'react';
import {Routes, Route} from 'react-router-dom';
import Home from "./pages/Home";
import EmailSuccess from './pages/EmailSuccess';
import Donation from './pages/Donation';

function App () {
  return (
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/emailsuccess" element={<EmailSuccess/>} />
        <Route path="/donation" element={<Donation/>} />
      </Routes>
  );
};

export default App;

