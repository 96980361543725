import React from "react";

const Wishlist = () => {
    return (
        <div class="wishlistSection  lightPurplebg">
            <div class="container">
                <h2 class="purpleFont">Wishlist</h2>
                <p class="wishlistQuote text-center purpleFont">Here are some wishes that are be greatly appreciated</p>
            </div>

            <div class="wishlistWrapper container">

                <div>
                    <p class="d-inline-flex gap-1">
                        <button class="wishListbtn" type="button" data-bs-toggle="collapse" data-bs-target="#wellness" aria-expanded="false" aria-controls="wellness">
                            Wellness
                        </button>
                    </p>
                    <div class="collapse" id="wellness">
                        <div class="card card-body">
                            &#8226; Manicure/Pedicure<br></br>
                            &#8226; Barbershop<br></br>
                            &#8226; Hair Salon<br></br>
                            &#8226; Massage
                        </div>
                    </div>
                </div>
                <div>
                    <p class="d-inline-flex gap-1">
                        <button class="wishListbtn" type="button" data-bs-toggle="collapse" data-bs-target="#homeImprovements" aria-expanded="false" aria-controls="homeImprovements">
                            Home Improvements
                        </button>
                    </p>
                    <div class="collapse" id="homeImprovements">
                        <div class="card card-body">
                            &#8226; Small plumbing jobs<br></br>
                            &#8226; Small repairs around the house<br></br>
                            &#8226; Painting<br></br>
                            &#8226; Landscaping - planting flowers<br></br>
                            &#8226; Mowing Lawn
                        </div>
                    </div>
                </div>
                <div>
                    <p class="d-inline-flex gap-1">
                        <button class="wishListbtn" type="button" data-bs-toggle="collapse" data-bs-target="#entertainments" aria-expanded="false" aria-controls="entertainments">
                            Entertainments
                        </button>
                    </p>
                    <div class="collapse" id="entertainments">
                        <div class="card card-body">
                            &#8226; Movies<br></br>
                            &#8226; Dinner to favorite restaurant<br></br> 
                            &#8226; Musical show<br></br>
                            &#8226; Stage play
                        </div>
                    </div>
                </div>
                <div>
                    <p class="d-inline-flex gap-1">
                        <button class="wishListbtn" type="button" data-bs-toggle="collapse" data-bs-target="#shopping" aria-expanded="false" aria-controls="shopping">
                            Shopping
                        </button>
                    </p>
                    <div class="collapse" id="shopping">
                        <div class="card card-body">
                            &#8226; Transporting to grocery store<br></br>
                            &#8226; Transporting to shopping mall
                        </div>
                    </div>
                </div>
                <div>
                    <p class="d-inline-flex gap-1">
                        <button class="wishListbtn" type="button" data-bs-toggle="collapse" data-bs-target="#crafts" aria-expanded="false" aria-controls="crafts">
                            Arts & Crafts
                        </button>
                    </p>
                    <div class="collapse" id="crafts">
                        <div class="card card-body">
                            &#8226; Craft supplies for knitting or crocheting<br></br>
                            &#8226; Art supplies for painting
                        </div>
                    </div>
                </div>
                <div>
                    <p class="d-inline-flex gap-1">
                        <button class="wishListbtn" type="button" data-bs-toggle="collapse" data-bs-target="#giftCards" aria-expanded="false" aria-controls="giftCards">
                            Gift cards
                        </button>
                    </p>
                    <div class="collapse" id="giftCards">
                        <div class="card card-body">
                            &#8226; Target<br></br>
                            &#8226; Walmart<br></br>
                            &#8226; Home Depot<br></br>
                            &#8226; Michael's<br></br>
                            &#8226; Hobby Lobby<br></br>
                            &#8226; Safeway<br></br>
                            &#8226; Giant<br></br>
                            &#8226; Food Lion<br></br>
                            &#8226; Wegmans
                        </div>
                    </div>
                </div>
                <div>
                    <p class="d-inline-flex gap-1">
                        <button class="wishListbtn" type="button" data-bs-toggle="collapse" data-bs-target="#check" aria-expanded="false" aria-controls="check">
                            Check
                        </button>
                    </p>
                    <div class="collapse" id="check">
                        <div class="card card-body">
                            &#8226; Disbursement of gift via check
                        </div>
                    </div>
                </div>
                <div>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfObIIcOcVgtPWIBb8jtluHL-5EBS58Smx0kBueGuWjLA3TAA/viewform?usp=pp_url" target="_blank" rel="noopener noreferrer">
                        <img class="pointer" src="/images/offerServiceIcon.svg" alt="Offer a Service Icon - Donation a service to a senior"></img>
                    </a>
                </div>
                
            </div>

        </div>

    );
}
export default Wishlist;