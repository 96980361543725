import React from "react";

const Contact = () => {
    return (
        <div>
            <div class="contactFaqWrapper"> 
                <div class="contactFaqHalf faqHalf container purpleFont">
                    <div>
                        <h2>FAQ</h2>
                    </div>
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    How do I vote for a senior?
                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">Click "Nominate" under the "Get Involved" section of the page or go to the following link.<br></br>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfoA1WL8keGND6yTM1Om6hjs0uGBCJijfv3a6RfJB9eZiCFYA/viewform?usp=pp_url" target="_blank" rel="noopener noreferrer">Nominate senior here</a> 
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    How do I donate or offer a service?
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">Click "Offer a Service" under the "Get Involved" section of the page or go to the following link. <br></br>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfObIIcOcVgtPWIBb8jtluHL-5EBS58Smx0kBueGuWjLA3TAA/viewform?usp=pp_url" target="_blank" rel="noopener noreferrer">Offer/donate a service here</a>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    How do I submit a senior into the BE365 Monthly Raffle?
                                </button>
                            </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">Click "Submit a Senior to be Nominated" under the "Winner of the Month" section of the page or go to the following link. <br></br>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSf_R5t2i77YpPNN60c1qORpDEEYqhJRg39atWcHWzigTk9UFg/viewform?usp=pp_url" target="_blank" rel="noopener noreferrer">Submit a senior to be nominated here</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="contactFaqHalf purpleBg whiteFont">
                    <div>
                        <h2>Contact Us</h2>
                    </div>
                    <div class="contactForm">
                        <form action="https://formsubmit.co/00f9f820b5be5b8364dccc44c80fc6b7" method="POST">
                            
                            <div class="row mb-3 g-2">
                                <div class="col-md-6">
                                    <input type="text" class="form-control" id="firstName" name="First Name" placeholder="First Name" required></input>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" class="form-control" id="lastName" name="Last Name" placeholder="Last Name" required></input>
                                </div>
                            </div>
                            <div class="mb-3">
                                <input type="email" class="form-control" id="email" name="Email" placeholder="Email" required></input>
                            </div>
                            <div class="mb-3">
                                <input type="text" class="form-control" id="subject" name="Subject" placeholder="Subject" required></input>
                            </div>
                            <div class="mb-3">
                                <textarea class="form-control" id="message" name="Message" rows="4" placeholder="Your message..." required></textarea>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button type="submit" class="contactFormbtn">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>     
        </div>
    );
}
export default Contact;