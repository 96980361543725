import React from "react";

const EmailSuccess = () => {
    return (
        <div class="emailsSuccessPage lightPurplebg purpleFont">
            <div class="emailsSuccessDiv container">
                <p class="emailSuccess emailSuccessP1"> Your email has been sent!</p>
                <p class="emailSuccess emailSuccessP2">Thank you!</p>
                <div class="emailSuccessReturnBtnDiv purpleBg">
                    <a class="emailSuccessBtn" href="https://birthdayeveryday365.com/"> Go back home</a>
                </div>
            </div>
        </div>
    )
}

export default EmailSuccess;