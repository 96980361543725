import React from "react";

const GetInvolved = () => {
    return (
        <div>
            <div>
                <h2 class="getInvolvedH2 purpleFont">Get Involved</h2>
            </div>
            <div class="getInvolvedWrapper">
                <div>
                    <a href="https://birthdayeveryday365.com/donation" target="_blank" rel="noopener noreferrer">
                        <img class="getInvolvedIcons" src="/images/offeringIcon.svg" alt="Offering Icon - Make a donation to BE365"></img>
                    </a>
                </div>
                <div>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfoA1WL8keGND6yTM1Om6hjs0uGBCJijfv3a6RfJB9eZiCFYA/viewform?usp=pp_url" target="_blank" rel="noopener noreferrer">
                        <img class="getInvolvedIcons" src="/images/nominateIcon.svg" alt="Nominate Icon - Nominate a senior to celebrate"></img>
                    </a>
                </div>
                <div>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfObIIcOcVgtPWIBb8jtluHL-5EBS58Smx0kBueGuWjLA3TAA/viewform?usp=pp_url" target="_blank" rel="noopener noreferrer">
                        <img class="getInvolvedIcons" src="/images/offerServiceIcon.svg" alt="Offer a Service Icon - Donation a service to a senior"></img>
                    </a>
                </div>
            </div>

        </div>
    );
}
export default GetInvolved;