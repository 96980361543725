import React from "react";

const Vote = () => {
    return (
        <div>
            <div class="nominateBtnWrapper">
                <div>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSf_R5t2i77YpPNN60c1qORpDEEYqhJRg39atWcHWzigTk9UFg/viewform?usp=pp_url" target="_blank" rel="noopener noreferrer">
                        <button class="nominateBtn whiteFont purpleBg">Submit a Senior <br></br>to be Nominated</button>
                    </a>
                </div>
                <div>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfoA1WL8keGND6yTM1Om6hjs0uGBCJijfv3a6RfJB9eZiCFYA/viewform?usp=pp_url" target="_blank" rel="noopener noreferrer">
                        <button class="nominateBtn whiteFont purpleBg">Nominate a Senior</button>
                    </a>
                </div>
            </div>
        </div>
    );
}
export default Vote;