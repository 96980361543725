import React from "react";

const About = () => {
    return (
        <div>
            <div class="aboutWrapper lightPurplebg">
                <div class="aboutWrapperItem aboutItem1">
                   <img  class="aboutImg leftAboutImg" src="/images/aboutImg1.png" alt="BE365 monthly winner."></img> 
                </div>

                <div class="aboutWrapperItem aboutItem2 purpleFont lightPurplebg">
                    <h2 class="aboutHeading">What We Do</h2>
                    <p class="aboutInfo">United Communities Against Poverty, Inc. (UCAP) has established a new program dedicated to Seniors 
                        65 years and older. This program pays homage to a senior whereby UCAP celebrates something from a wishlist 
                        each month. 
                        <div class="collapse" id="collapseExample">
                            <div>
                                The premise behind the program is to let seniors know in a small way that they are never forgotten. 
                                Here at UCAP we understand that seniors are the cornerstones of wisdom, filled with rich heritage, and have endured many 
                                challenges in their lives. And for these reasons, we wanted another way to acknowledge a senior Just Because, 
                                hence UCAP developed a program called Birthday Everyday (BE) 365. Look at life everyday as a blessing to be celebrated, 
                                like if it were your birthday every day. Birthday Everyday is a mindset of living your Glory Years in a manner that is 
                                focused on wellbeing.
                            </div>
                        </div>
                        <p>
                            <button class="btn readMoreBtn purpleFont" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                Read More...
                            </button>
                        </p>
                    </p>
                </div>
                <div class="aboutWrapperItem aboutItem3">
                   <img  class="aboutImg rightAboutImg" src="/images/aboutImg2.png" alt="BE365 monthly winner."></img>
                </div>
            </div>


            <div class="aboutWrapperMobile lightPurplebg">
                <div class="purpleFont lightPurplebg">
                    <h2 class="aboutHeading">What We Do</h2>
                    <p class="aboutInfo">United Communities Against Poverty, Inc. (UCAP) has established a new program dedicated to Seniors 
                        65 years and older. This program pays homage to a senior whereby UCAP celebrates something from a wishlist 
                        each month. 
                        <div class="collapse" id="collapseExample">
                            <div>
                                The premise behind the program is to let seniors know in a small way that they are never forgotten. 
                                Here at UCAP we understand that seniors are the cornerstones of wisdom, filled with rich heritage, and have endured many 
                                challenges in their lives. And for these reasons, we wanted another way to acknowledge a senior Just Because, 
                                hence UCAP developed a program called Birthday Everyday (BE) 365. Look at life everyday as a blessing to be celebrated, 
                                like if it were your birthday every day. Birthday Everyday is a mindset of living your Glory Years in a manner that is 
                                focused on wellbeing.
                            </div>
                        </div>
                        <p>
                            <button class="btn readMoreBtn purpleFont" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                Read More...
                            </button>
                        </p>
                    </p>
                </div>

                <div class="aboutImgWrapperMobile">
                    <div>
                        <img  class="aboutImgMobile" src="/images/aboutImg3.png" alt="BE365 monthly winner."></img> 
                    </div>
                    
                    <div>
                        <img  class="aboutImgMobile" src="/images/aboutImg4.png" alt="BE365 monthly winner."></img>  
                    </div>
                </div>
            </div>
        </div>
    );
}
export default About;