import React from "react";

const Footer = () => {
    return (
        <section>
            <div class="footerSection lightPurplebg">
                <div class="footerWrapper container">
                    <div>
                        <img class="footerLogo" src="/images/BE365small.svg" alt="Birthday Everyday 365 logo"></img>
                    </div>
                    <div>
                        <p class="purpleFont"> 
                            <strong>Email: </strong>
                            <a class="footerEmail" href="mailto:beveryday365@gmail.com" alt="BE365 email address">beveryday365@gmail.com</a>
                        </p>
                    </div>
                    <div class="footerIconsWrapper">
                        <div>
                            <a href="https://birthdayeveryday365.com/donation" target="_blank" rel="noopener noreferrer">
                                <img class="footerIcons" src="/images/offeringIcon.svg" alt="Offering Icon - Make a donation to BE365"></img>
                            </a>
                        </div>
                        <div>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfoA1WL8keGND6yTM1Om6hjs0uGBCJijfv3a6RfJB9eZiCFYA/viewform?usp=pp_url" target="_blank" rel="noopener noreferrer">
                                <img class="footerIcons" src="/images/nominateIcon.svg" alt="Nominate Icon - Nominate a senior to celebrate"></img>
                            </a>
                        </div>
                        <div>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfObIIcOcVgtPWIBb8jtluHL-5EBS58Smx0kBueGuWjLA3TAA/viewform?usp=pp_url" target="_blank" rel="noopener noreferrer">
                                <img class="footerIcons" src="/images/offerServiceIcon.svg" alt="Offer a Service Icon - Donation a service to a senior"></img>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mobileFooterSection lightPurplebg">
                <div class="mobileFooterWrapper">
                    <div class="mobileFooterItem">
                        <div class="mobileFooterEmailDiv bottom-border">
                            <p class="purpleFont footerEmailPara"> 
                                <strong>Email: </strong>
                                <a class="footerEmail" href="mailto:beveryday365@gmail.com" alt="BE365 email address">beveryday365@gmail.com</a>
                            </p>
                        </div>
                        <div class="mobileFooterIconsWrapper">
                            <div>
                                <a href="https://birthdayeveryday365.com/" target="_blank" rel="noopener noreferrer">
                                    <img class="mobileFooterIcons" src="/images/offeringIcon.svg" alt="Offering Icon - Make a donation to BE365"></img>
                                </a>
                            </div>
                            <div>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfoA1WL8keGND6yTM1Om6hjs0uGBCJijfv3a6RfJB9eZiCFYA/viewform?usp=pp_url" target="_blank" rel="noopener noreferrer">
                                    <img class="mobileFooterIcons" src="/images/nominateIcon.svg" alt="Nominate Icon - Nominate a senior to celebrate"></img>
                                </a>
                            </div>
                            <div>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfObIIcOcVgtPWIBb8jtluHL-5EBS58Smx0kBueGuWjLA3TAA/viewform?usp=pp_url" target="_blank" rel="noopener noreferrer">
                                    <img class="mobileFooterIcons" src="/images/offerServiceIcon.svg" alt="Offer a Service Icon - Donation a service to a senior"></img>
                                </a>
                            </div>
                        </div>
                        <div class="mobileFooterLogo">
                            <img class="mobileFooterLogo" src="/images/BE365small.svg" alt="Birthday Everyday 365 logo"></img>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyrightDiv lightPurplebg">
                <a class="copyright container" href="https://www.codenamekd.dev/" target="_blank" rel="noopener noreferrer">2024 © Birthday Everyday BE365 | by CodeNameKD</a>
            </div>
        </section>
    );
}
export default Footer;