import React from "react";
import Navbar from "../components/Navbar";
import About from "./About";
import GetInvolved from "./GetInvolved";
import News from "./News";
import Vote from "./Vote";
import Wishlist from "./Wishlist";
import Contact from "./Contact";
import Footer from "../components/Footer";

const Home = () => {
    return (
        <div>
            <section> <div> <Navbar/> </div> </section>

            <section>

                <div class="landingSectionDesktop">
                    <div class="landingImg landingContent">
                        <div class="landingHeading purpleFont">
                            <h1 class="landingH1">Celebrate Our<br></br>Seniors!</h1>
                            <p class="landingP">Just Because</p>
                        </div>
                    </div>
                </div>


                <div class="landingSectionMobile">
                    <div class="landingImgMobile landingContentMobile">
                        <div class="landingHeadingMobile container whiteFont">
                            <h1 class="landingH1Mobile">Celebrate Our<br></br>Seniors!</h1>
                            <p class="landingPMobile">Just Because</p>
                        </div>
                    </div>
                </div>
               
            
            </section>

            <section id="aboutus"> <div><About/></div> </section>
            <section id="getinvolved"> <div><GetInvolved/></div> </section>
            <section id="news"> <div> <News/> </div> </section>
            <section> <div> <Vote/> </div> </section>
            <section id="wishlist"> <div> <Wishlist/> </div> </section>   
            <section> <div> <Contact/> </div> </section>
            <section> <div> <Footer/> </div> </section>
        </div>
    );
}
export default Home;