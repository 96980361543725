import React from "react";

const News = () => {
    return (
        <div> 
            <div class="newsSection purpleBg">

                <div class="newsWrapper">  

                    <div class="newsWrapperItem newsItem1 whiteFont">
                        <div class="newsSectionHeader whiteFont">
                            <h2>Winner of the Month</h2>
                        </div>

                        <p class="newsParagraph">
                            <strong>Name:</strong> Jane Doe
                            <br></br>
                            <br></br>
                            <strong>Description/About:</strong> Lorem ipsum dolor sit amet. 
                            Ea dolor delectus et quibusdam explicabo cum sapiente 
                            autem 33 aperiam quasi. 33 suscipit iusto rem natus nemo eum 
                            dolores esse sed reprehenderit delectus quo quibusdam nobis
                            <br></br>
                            <br></br>
                            <strong>Won:</strong> Spa Day
                            <br></br>
                            <br></br>
                            <strong>Thank you:</strong> Jonathan James
                        </p>
                    </div>

                    <div class="newsWrapperItem newsItem2">
                        <img class="winnerImg" src="/images/winnerImg.png" alt="BE365 winner"></img>
                    </div>
                </div>
            </div>
            

            <div class="newsSectionMobile purpleBg">
                <div class="whiteFont">
                    <div class="newsSectionHeader">
                        <h2 class="newsSectionH2">Winner of the Month</h2>
                    </div>

                    <div class="winnerImgDiv">
                        <img class="winnerImg" src="/images/winnerImg.png" alt="BE365 winner"></img>
                    </div>

                    <p class="newsParagraph">
                        <strong>Name:</strong> Jane Doe
                        <br></br>
                        <br></br>
                        <strong>Description/About:</strong> Lorem ipsum dolor sit amet. 
                        Ea dolor delectus et quibusdam explicabo cum sapiente 
                        autem 33 aperiam quasi. 33 suscipit iusto rem natus nemo eum 
                        dolores esse sed reprehenderit delectus quo quibusdam nobis
                        <br></br>
                        <br></br>
                        <strong>Won:</strong> Spa Day
                        <br></br>
                        <br></br>
                        <strong>Thank you:</strong> Jonathan James
                    </p>
                </div>
            </div>
            
        </div>
    );
}
export default News;