import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";


const Navbar = () => {
    return(
        <div>

            <div class="navBarSectionDesktop lightPurplebg">
                <div class="desktopNavbarWrapper">
                    <div>
                        <ul>
                            <li class="desktopLogoLi"><a class="desktopLogo NavLi" href="/"><img class="desktopLogoLarge" src="/images/BE365LogoLarge.svg" alt="Birthday Everyday 365 Logo"></img></a></li>
                        </ul>
                    </div>
                    <div>
                        <ul class="desktopNavLinkWrapper">
                            <li class="desktopNavLink"><AnchorLink class="navLink NavLi purpleFont" href="#getinvolved">Get Involved</AnchorLink></li>
                            <li class="desktopNavLink"><AnchorLink class="navLink NavLi purpleFont" href="#aboutus">About Us</AnchorLink></li>
                            <li class="desktopNavLink"><AnchorLink class="navLink NavLi purpleFont" href="#news">News</AnchorLink></li>
                            <li class="desktopNavLink"><AnchorLink class="navLink NavLi purpleFont" href="#wishlist">Wishlist</AnchorLink></li>
                            <a class="desktopDonateBtn" href="https://birthdayeveryday365.com/donation">Donate</a>
                            
                        </ul>
                    </div>
                </div>
            </div>

            <div class="navBarSectionMobile">
                <nav class="navbar fixed-top lightPurplebg">
                    <div class="container-fluid">
                        <a class="navbar-brand" href="/"><img class="mobileLogoSmall" src="/images/BE365small.svg" alt="Birthday Everyday 365 Logo"></img></a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="offcanvas offcanvas-start lightPurplebg" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" data-bs-dismiss="offcanvas">
                            <div class="offcanvas-header">
                                <h5 class="offcanvas-title" id="offcanvasNavbarLabel"><img class="mobileLogoSmall" src="/images/BE365small.svg" alt="Birthday Everyday 365 Logo"></img></h5>
                                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div class="mobileNavItems offcanvas-body">
                                <ul class="navbar-nav">
                                    <li class="nav-item">
                                        <a class="nav-link" href="#getinvolved">Get Involved</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#aboutus">About Us</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#news">News</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#wishlist">Wishlist</a>
                                    </li>
                                    <li class="nav-item">
                                        <div class="mobileDonateBtn">
                                            <a class="nav-link mobileDonateBtnText" href="https://birthdayeveryday365.com/donation">Donate</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>


        </div>
    );
}

export default Navbar;