import React from "react";
import Footer from "../components/Footer";

const Donation = () => {
    return (
        <div class="donationPageSection">
            <div class="donationContentWrap">
                <div class="donationContent">
                    <div class="purpleFont purpleFont container">
                        <h2 class="donationPageHeading">Thank you for your willingness to Donate!</h2>
                        <p>Our donation collection system is under construction.</p>
                        <br></br>
                        <p>Please check back later.</p>
                    </div>
                    <div class="goBackBtnDiv">
                        <a class="goBackBtn" href="https://birthdayeveryday365.com/">Go back</a>
                    </div>
                </div>
            </div>
            
            <Footer/> 
        </div>

    )
}

export default Donation;